import React, { useState } from "react";
import { FaHome, FaInfoCircle, FaProductHunt, FaPhone } from "react-icons/fa";
import MobileSidebar from "../MobileSidebar";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isMobileOptionMenuOpen, setIsMobileOptionMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileOptionMenu = () => {
    setIsMobileOptionMenuOpen(!isMobileOptionMenuOpen);
  };

  return (
    <nav className="bg-white sticky top-0 z-50 shadow-bottom">
      <div className="max-w-full px-4 sm:px-6 lg:px-44">
        <div className="flex items-center justify-between h-16 ">
          <div className="w-[20%] flex-shrink-0">
            <a href="/">
              <img src="/images/logo.png" alt="gydantis" />
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex space-x-10 mr-5">
              <a
                href="/"
                className={`flex flex-col items-center justify-center ${
                  location.pathname === "/"
                    ? "text-red-400 "
                    : "hover:text-red-400"
                }`}
              >
                <FaHome className="text-2xl" />
                <span className="text-xs hover:text-red-400">Home</span>
              </a>
              <a
                href="/products"
                className={`flex flex-col items-center justify-center ${
                  location.pathname.startsWith("/products")
                    ? "text-red-400 "
                    : "hover:text-red-400"
                }`}
              >
                <FaProductHunt className="text-2xl" />
                <span className="text-xs hover:text-red-400">Products</span>
              </a>
              <a
                href="/contact"
                className={`flex flex-col items-center justify-center ${
                  location.pathname === "/contact"
                    ? "text-red-400 "
                    : "hover:text-red-400"
                }`}
              >
                <FaPhone className="text-2xl" />
                <span className="text-xs hover:text-red-400">Contact Us</span>
              </a>
              <a
                href="/about"
                className={`flex flex-col items-center justify-center ${
                  location.pathname === "/about"
                    ? "text-red-400 "
                    : "hover:text-red-400"
                }`}
              >
                <FaInfoCircle className="text-2xl" />
                <span className="text-xs hover:text-red-400">About Us</span>
              </a>
            </div>
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMobileOptionMenu}
              className="text-gray-600 hover:text-black focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>

            {/* Mobile Sidebar */}
            {isMobileOptionMenuOpen && <MobileSidebar />}
          </div>
        </div>
      </div>
      {/* Black line at the end of the nav */}
      {/* <div className="h-[1px] bg-gray-400"></div> */}
    </nav>
  );
};

export default Navbar;
