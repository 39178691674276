import React from "react";

const productsData = [
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    // ... other properties
  },
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    // ... other properties
  },
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    // ... other properties
  },
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    // ... other properties
  },
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    // ... other properties
  },
  {
    id: 2,
    imageSrc: "https://i.imgur.com/np0H3nf.png",
    title: "VAXTVIT KID 100 ml",
    sub_heading: "A powerful and extra energy formula for growing children",
    description: "Essential Amino Acids + Minerals + Vitamins + Zinc",
    // ... other properties
  },
  // Add more products as needed
];

const RelatedProducts = () => {
  // State to track the current scroll position
  const [scrollPosition, setScrollPosition] = React.useState(0);

  // Function to handle scrolling to the left
  const scrollLeft = () => {
    setScrollPosition(Math.max(scrollPosition - 1, 0));
  };

  // Function to handle scrolling to the right
  const scrollRight = () => {
    setScrollPosition(Math.min(scrollPosition + 1, productsData.length - 1));
  };

  // Calculate the style for the product container based on the scroll position
  const productContainerStyle = {
    transform: `translateX(-${scrollPosition * 100}%)`,
    transition: "transform 0.3s ease",
    display: "flex",
  };

  return (
    <div className="max-w-screen-xl mt-5 flex items-center">
      <button
        onClick={scrollLeft}
        className={`bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-4 px-4 rounded mr-2 ${
          scrollPosition === 0 ? "cursor-not-allowed opacity-50" : ""
        }`}
        disabled={scrollPosition === 0}
      >
        {"<"}
      </button>

      <div className="overflow-x-auto flex" style={{ width: "64rem" }}>
        <div className="flex" style={productContainerStyle}>
          {productsData.map((product, index) => (
            <div
              key={index}
              className="w-64 bg-white border rounded-md p-4 mx-2"
            >
              <img
                src={product.imageSrc}
                alt={`Product ${product.id}`}
                className="mb-2 rounded"
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <h2 className="text-lg font-semibold mb-2">{product.title}</h2>
              <p className="text-sm text-gray-600 mb-4">
                {product.sub_heading}
              </p>
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={scrollRight}
        className={`bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-4 px-4 rounded ml-2 ${
          scrollPosition === productsData.length - 1
            ? "cursor-not-allowed opacity-50"
            : ""
        }`}
        disabled={scrollPosition === productsData.length - 1}
      >
        {">"}
      </button>
    </div>
  );
};

export default RelatedProducts;