import React from "react";
import { useLocation } from "react-router-dom";

const MobileSidebar = () => {
  const location = useLocation();

  return (
    <div className="absolute left-0 top-0 h-full">
      <div className="bg-white w-64 shadow-lg p-4 h-screen">
        <ul className="space-y-2">
          <li>
            <button
              className={`text-lg focus:outline-none px-4 py-2 rounded-md border border-black-600 hover:bg-red-100 ${
                location.pathname === "/" ? "bg-red-300" : "bg-white"
              }`}
              onClick={() => (window.location.href = "/")}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className={`text-lg focus:outline-none px-4 py-2 rounded-md border border-black-600 hover:bg-red-100 ${
                location.pathname === "/products" ? "bg-red-300" : "bg-white"
              }`}
              onClick={() => (window.location.href = "/products")}
            >
              Products
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileSidebar;
