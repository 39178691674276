import React, { useState } from "react";

const Faqs = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // Sample FAQ data - replace this with your actual data
  const faqData = [
    {
      question: "Who are we?",
      answer:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    },
    {
      question: "Who are we?",
      answer:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    },
    {
      question: "Who are we?",
      answer:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    },
    {
      question: "Who are we?",
      answer:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    },
    
    // Add more quesitons
  ];

  const handleQuestionClick = (index) => {
    setSelectedQuestion(index === selectedQuestion ? null : index);
  };
  return (
    <div className="flex flex-col items-center lg:m-44 bg-black text-white rounded-lg lg:mb-36 ">
      <h1 className="text-3xl text-white mt-7 mb-2 lg:text-4xl font-bold lg:mb-7">
        FAQs
      </h1>
      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-1/2 p-5 lg:border-r lg:border-b-0">
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer p-4 mb-2 border rounded-md ${
                index === selectedQuestion
                  ? "bg-white text-black font-bold"
                  : "bg-black text-white font-normal"
              }`}
            >
              <div onClick={() => handleQuestionClick(index)}>
                {item.question}
              </div>
              {/* Display answer below the clicked question in mobile view */}
              <div className={`font-normal mt-2 lg:hidden`}>
                {selectedQuestion === index && faqData[selectedQuestion].answer}
              </div>
            </div>
          ))}
        </div>

        {/* Show this div only in tablet and desktop view (lg and md), not in mobile view (sm) */}
        <div className="hidden lg:w-1/2 lg:flex p-5 justify-center items-center bg-white text-black lg:m-5 border rounded-md lg:justify-start lg:items-start">
          {selectedQuestion !== null && (
            <div className="font-normal">
              {faqData[selectedQuestion].answer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
