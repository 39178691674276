import React from "react";

const Banner = () => {
  return (
    <div className="w-[95%] m-6 mt-16 lg:mb-44 md:m-6 md:mt-4 flex flex-row lg:flex-row rounded-[20px]">
      <div className="font-josefin-sans w-full flex flex-col lg:w-2/3 lg:pl-28 md:mx-10 md:mt-44 mt-0">
        <h3 className="mb-5 text-red-400 font-bold text-2xl md:text-3xl lg:text-5xl">
          Welcome to! <br />
          <br /> GYDANTIS PHARMACEUTICALS LLP
        </h3>
        <p className="my-2 text-xl font-medium mb-8 text-green-700">
          LLPIN: AAV-3365
        </p>
      </div>
      {/* Center the image on larger screens (lg) */}
      <div className="w-full lg:w-1/2 lg:flex items-center justify-center  md:flex ">
        <img
          src="/images/Child adoption-pana.png"
          alt="banner"
          className="w-full md:w-1/2 lg:w-full transform scale-x-[-1] mt-5"
        />
      </div>
    </div>
  );
};

export default Banner;
