// ProductList.js
import React from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import productsData from "./data";

const ProductList = () => {
  const navigate = useNavigate();

  const handleProductClick = (product) => {
    navigate(`/products/${product.title}`, { state: { product } });
  };

  return (
    <div className="product-list bg-gray-100 md:py-10 md:flex md:flex-wrap gap-5 justify-center">
      {productsData.map((product, index) => (
        <ProductCard
          key={index}
          product={product}
          onClick={() => handleProductClick(product)}
        />
      ))}
    </div>
  );
};

export default ProductList;
