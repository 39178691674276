import React from "react";
import { FaLinkedinIn, FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="font-josefin-sans">
      <div className="px-4 lg:pt-28 bg-gray-200 md:px-10 lg:px-20 xl:px-44 main-container-for-logo-company-user-teacher flex flex-col md:flex-row justify-between">
        <div className="logo-here mb-6 md:mb-0 md:mr-8">
          <img src="/images/logo.png" alt="logo" className="w-52" />
        </div>

        <div className="company mb-6 md:mb-0 md:mr-8">
          <h2 className="text-base font-semibold mb-2 md:mb-4">Company</h2>
          <ul className="space-y-2 lg:text-sm">
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/career">Career</a>
            </li>

            <li>
              <a href="/privacy&policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/term&conditions">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="user mb-6 md:mb-0 md:mr-8">
          <h2 className="text-base font-semibold mb-2 md:mb-4">Quick Links</h2>
          <ul className="space-y-2 lg:text-sm">
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/user-topic">Products</a>
            </li>
            <li>
              <a href="/contactUs">Contact Us</a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col space-y-5 startup-india">
          <img
            src="/images/FSSAI_logo.png"
            alt="Startup India"
            className="w-40 md:w-48 mt-4 md:mt-0 "
          />
        </div>
      </div>
      <div className=" py-3 md:py-0 md:pr-20 flex justify-center md:justify-between lg:py-3 xl:px-44 lg:p-20">
        <p className="text-gray-600">
          &copy; {new Date().getFullYear()} Gydantis | All rights reserved
        </p>
        <div className="flex flex-row lg:space-x-16">
          <a
            href="https://www.instagram.com/gydantis/"
            className="mr-4 lg:mr-0 text-blue-800"
          >
            <FaLinkedinIn size={25} />
          </a>
          <a
            href="https://www.instagram.com/gydantis/"
            className="mr-4 lg:mr-0 text-blue-800"
          >
            <FaFacebook size={25} />
          </a>
          <a
            href="https://www.instagram.com/gydantis/"
            className="mr-4 lg:mr-0 text-red-500"
          >
            <FaInstagram size={25} />
          </a>
        </div>
      </div>
      <div className="flex text-xs md:text-sm justify-center mt-2 md:mt-3 lg:mt-0"></div>
    </footer>
  );
};

export default Footer;
