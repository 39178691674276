import React from "react";
import { Link } from "react-router-dom";
// import ProductCardSlider from "../ProductCardSlider";
const LandingPage = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-rose-200 to-red-200 flex p-5 lg:mb-24 md:mb-24 mb-0">
        <div className="p-5 mt-5 mb-6 text-4xl m-auto text-center max-w-4xl font-bold leading-tight  lg:pb-24 md:pt-24 md:pb-24 pb-0 pt-0">
          <h3 className="lg:text-4xl md:text-4xl text-2xl leading-normal">
            "Your <span className="text-green-500">Health</span>, Our
            Commitment. <span className="text-green-500">Quality</span> Care and
            Wellness Solutions."
          </h3>
        </div>
      </div>

      {/* ============================================ */}
      {/* <div className="w-full lg:pb-44 lg:pt-44 md:pb-44 md:pt-44 pt-0 pb-0 bg-gray-100 mb-20 justify-center text-3xl flex flex-col lg:flex-row md:flex-row"> */}

      <div className="w-full px-4 lg:px-44 py-10 lg:py-20 md:py-20 md:px-10 pt-0 pb-0 justify-center text-xl md:text-2xl lg:text-3xl flex flex-col lg:flex-row-reverse md:flex-row">
        <div className=" lg:py-5 font-josefin-sans w-full lg:w-1/2 md:w-1/2 flex flex-col justify-start">
          <br />
          <h1 className="text-2xl lg:text-4xl mb-5 font-bold">
            Nurturing Your Health: Your Trusted Pharmacy Partner
          </h1>
          <p className="lg:text-lg">
            At Gydantis, we are dedicated to providing you with top-notch
            healthcare services and products. Our mission is to ensure your
            well-being by offering a wide range of pharmaceutical solutions,
            wellness products, and expert guidance. With a commitment to
            excellence, we are your trusted partners in health.
          </p>
        </div>
        <div className="w-full lg:w-1/2 md:w-1/2 flex justify-start mt-5 lg:mt-0 md:mt-0">
          <img alt="who" src="/images/Medical care-bro.png" q />
        </div>
      </div>

      {/* ======================= augmented reality section start here=========================== */}
      {/* ============================================ */}
      {/* <div className="w-full lg:pb-44 lg:pt-44 md:pb-44 md:pt-44 pt-0 pb-0 bg-gray-100 mb-20 justify-center text-3xl flex flex-col lg:flex-row md:flex-row"> */}

      <div className=" w-full px-4 lg:px-44 py-10 lg:py-20 md:py-20 md:px-10 pt-0 pb-0 justify-center text-xl md:text-2xl lg:text-3xl flex flex-col lg:flex-row md:flex-row">
        <div className=" w-full lg:w-1/2 md:w-1/2 flex flex-col justify-center">
          <br />
          <h1 className="text-2xl lg:text-4xl mb-5 font-bold">
            Your Trusted Healthcare Partner
          </h1>
          <p className="lg:text-lg">
            With Gydantis, you're not just a customer; you're a valued member of
            our healthcare community. We're dedicated to building lasting
            relationships and ensuring you receive the best care possible.
          </p>
        </div>
        <div className="w-full lg:w-1/2 md:w-1/2 flex justify-center mt-5 lg:mt-0 md:mt-0">
          <img
            alt=""
            src="/images/Remedy-amico.png"
            className="w-full lg:w-5/6 rounded-2xl"
          />
        </div>
      </div>

      {/* ======================= augmented reality section start here=========================== */}
      <div className="w-full px-4 lg:px-44 py-10 lg:py-20 md:py-20 md:px-10 pt-0 pb-0 justify-center text-xl md:text-2xl lg:text-3xl flex flex-col lg:flex-row-reverse md:flex-row">
        <div className=" lg:py-5 font-josefin-sans w-full lg:w-1/2 md:w-1/2 flex flex-col justify-center">
          <h1 className="text-2xl lg:text-4xl mb-5 font-bold">
            Convenient Services
          </h1>
          <p className="lg:text-lg">
            We understand the importance of convenience in managing your health.
            That's why we offer online prescription refills, easy order
            placement, and multiple payment options. Explore our user-friendly
            platform to streamline your pharmacy experience.
          </p>
        </div>
        <div className="w-full lg:w-1/2 md:w-1/2 flex justify-start mt-5 lg:mt-0 md:mt-0">
          <img
            alt="Coolpic"
            src="/images/Alzheimer-bro.png"
            className="w-full lg:w-5/6 rounded-2xl"
          />
        </div>
      </div>

      {/* ===============================mentor section starts here================= */}
    
     
      
     
      {/* ===============================job internship section starts here================= */}

      <div className=" w-full px-4 lg:px-20 py-10 lg:py-20 md:py-20 md:px-10 pt-0 pb-0 justify-center text-xl md:text-2xl lg:text-3xl flex flex-col lg:flex-row md:flex-row">
        <h1 className="p-5 mt-5 text-2xl lg:text-4xl text-center max-w-4xl font-bold leading-tight lg:pt-24 lg:pb-24 md:pt-24 md:pb-24 pt-0 pb-0 justify-center lg:max-w-3xl md:max-w-3xl">
          Convenient Services
        </h1>
        <div className="w-full lg:w-1/2 md:w-1/2 flex justify-center mt-5 lg:mt-0 md:mt-0">
          <img
            alt="jobs"
            src="https://plus.unsplash.com/premium_photo-1681494553170-8efc034ccf0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
            className="w-full lg:w-5/6 rounded-2xl"
          />
        </div>
      </div>
      {/* =================================closing line starts here====================== */}
      <div className="w-full lg:mt-28 flex flex-col justify-center items-center">
        <div className="w-full px-4 lg:px-10 py-10 lg:py-10 md:py-20 md:px-10 pt-0 pb-4 text-center">
          <h1 className="text-2xl mt-6 lg:text-4xl mb-2 font-semibold">
            Stay Informed
          </h1>
          <p className="lg:text-lg">
            Stay informed about the latest health trends, medication updates,
            and wellness tips through our informative blog. We're committed to
            empowering you with the knowledge you need to make informed
            decisions about your health.
          </p>
        </div>
        <div className="w-full flex justify-center pb-6">
          <Link to="/usersignup">
            <button className="bg-blue-500 h-12 text-black text-sm font-semibold px-6 rounded-3xl">
              Blogs
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
