//adding comment
import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Home/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ProductList from "./components/Home/ProductList";
import ProductDetail from "./components/ProductDetails";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/contact" element={<ContactUs />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/products" element={<ProductList/>} />
        <Route path="/products/:title" element={<ProductDetail/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
