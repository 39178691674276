const data = [
  {
    id: 1,
    imageSrc: "https://i.imgur.com/RgmPK37.png",
    title: "Platevax Syrup 100 ml and Tablets",
    sub_heading: "Natural Formulation for Natural Care",
    description:
      "A trusted remedy for Dengue, Malaria, fever, allergy & raising platelet counts. Relief from the symptoms of Hay Fever, Allergies, and Skin allergies.",
    health_benefits: [
      "Useful for all kinds of Flu and Fever",
      "Improves digestive system and increases hunger",
      "Neem and Giloy considered as a Blood Purifier",
      "Controls Itching, eczema, and fungal infection",
      "Contains powerful antioxidants that boost immunity",
    ],

    usage_instructions: [
      "Children: 5ml Twice a day",
      "Adults: 5ml-10ml three times a day, or as directed by the physician",
      "Do not stop or skip the Medicine without physician’s Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    key_features: [
      "Natural solution for fast recovery from fever",
      "Increases Platelets and boosts immunity",
      "100% Sugar-free",
    ],
    ingredients: "Giloy, Papaya leaf juice, goat milk, kiwi, vitamin e, etc",
    safety_information:
      "Provide the Safety information, Including warnings, Precautions, and potential Side Effects.",
  },
  {
    id: 2,

    imageSrc: "https://i.imgur.com/np0H3nf.png",
    title: "VAXTVIT KID 100 ml",
    sub_heading: "A powerful and extra energy formula for growing children",
    description: "Essential Amino Acids + Minerals + Vitamins + Zinc",
    health_benefits: [
      "Period of active growth",
      "General Debility",
      "Nutritional Deficiencies",
      "Increases muscles growth, Provides energy, and reduces Fatigue",
      "Promotes weight gain, Builds immuno competence",
    ],
    usage_instructions: [
      "Take 5ml -10ml three times a day or as directed by the physician",
      "Do not stop or skip the Medicine without physician's Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    ingredients:
      "Essential amino acids, mutiminerals, multivitamins, selenium, zinc, vitamin A, D, C, B1, B6",
    safety_information:
      "Provide the Safety information, Including warnings, Precautions, and potential Side Effects.",
  },
  {
    id: 3,

    imageSrc: "https://imgur.com/wQCVBf2.png",
    title: "VAXTUR Tablets",
    sub_heading: "VAXTUR (Orange Flavor & Sugar Free) Tablets",
    description:
      "The slimmest tablets reduce the pill burden, making it a perfect supplement for overcoming Iron deficiency.",
    health_benefits: [
      "Contains Ferros Bisyglycinate 100mg, Folic Acid 1.5mg, Zinc 22.5mg, and Cyanocobalamin 750mcg",
      "Recommended for Iron Deficiency, Pregnancy, and Lactation",
      "Prevents anemia during pregnancy and lactation",
    ],
    usage_instructions: [
      "Take orally, as advised by the physician",
      "Do not stop or skip the Medicine without physician's Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    precaution_information: [
      "If allergic to VAXTUR, inform the physician immediately",
      "If any other complications arise, inform the physician immediately",
      "Consult with the physician during pregnancy and lactation",
    ],
    storage_condition:
      "Store in a dry place, at a temperature not exceeding 30ºC. Protect from Direct light.",
  },
  {
    id: 4,
    imageSrc: "https://imgur.com/ACzBPPq.png",
    title: "VAXTVIT-17G",
    sub_heading:
      "Perfect combination of Rejuvenates and strengthens Body organs and helps maintain good health",
    description:
      "New & Improved Daily Multivitamin contains Omega3, Vitamins, minerals, antioxidants, amino acids, and trace elements for immune support, vitamin deficiencies, and good health.",
    health_benefits: [
      "Vaxt Vit 17G is a pioneer in Multivitamin Brand",
      "Boosts your energy, immunity and provides a healthy life",
      "Helps to protect from various viruses and infections",
      "Aids in chronic fatigue and debility",
      "Aids in Convalescence and geriatrics",
      "Supports cells life, bones, heart, eyes, hairs, skin, etc",
    ],
    directions_for_use: [
      "Children: 5ml daily",
      "Adults: 5ml-10ml daily, 1-2 tablets daily or as directed by the physician",
    ],
    safety_information: [
      "Store in a cool and dry place away from direct sunlight",
      "Keep out of reach of the children",
      "Pregnant and lactating women and individuals with any pre-existing condition should consult a doctor before taking the medicine",
      "In case of symptoms of any illness, please consult your physician",
    ],
  },
  {
    id: 5,
    title: "VAXT-LIV",

    imageSrc: "https://i.imgur.com/au5ylpF.png",
    sub_heading: "A powerful Ayurvedic Liver Tonic",
    description: "Complete Protection Against Acute and Chronic Liver Disorder",
    health_benefits: [
      "Provides Complete Protection for the Liver",
      "Supports Overall Liver Health",
      "Helps With acute and Chronic Liver Disorder",
    ],
    usage_instructions: [
      "Take 5 to 10 ML Daily, preferably after Meals",
      "Do not stop or skip the Medicine without physician’s Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    key_features: [
      "Improves Liver Functions & Digestion",
      "Useful against Abdominal pain & Swelling",
      "Regulates a Healthy Cholesterol Range",
      "For Liver Detoxification",
    ],
    ingredients: "List of Ingredients in the Product",
    safety_information:
      "Provide the Safety information, Including warnings, Precautions, and potential Side Effects",
  },
  {
    id: 6,

    imageSrc: "https://i.imgur.com/ZAjCvGO.png",

    title: "VAXTUR Syrup",
    sub_heading: "VAXTUR (Orange Flavor & Sugar Free) Syrup 200ml",
    description:
      "VAXTUR is a healthy syrup that comes in Orange flavor and is completely sugar-free. ",
    health_benefits: [
      "Contains Ferros Bisyglycinate 100mg, Folic Acid 1.5mg, Zinc 22.5mg, and Cyanocobalamin 750mcg",
      "Recommended for Iron Deficiency, Pregnancy, and Lactation",
      "Prevents anemia during pregnancy and lactation",
    ],
    usage_instructions: [
      "Take orally, as advised by the physician",
      "Do not stop or skip the Medicine without physician's Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    precaution_information: [
      "If allergic to VAXTUR, inform the physician immediately",
      "If any other complications arise, inform the physician immediately",
      "Consult with the physician during pregnancy and lactation",
    ],
    storage_condition:
      "Store in a dry place, at a temperature not exceeding 30ºC. Protect from Direct light.",
  },
  {
    id: 7,
    imageSrc: "https://imgur.com/TLUZ2pI.png",
    title: "Qufa Syrup 100 ml",
    sub_heading: "Forget all types of side effects of modern cough syrup",
    description:
      "Fast action Formulation. An Ayurvedic Proprietary Medicine. QUFA = WET COUGH + DRY COUGH",
    health_benefits: [
      "Respiratory tract infections of viral origin",
      "Recurrent sore throat",
      "Smoker's cough",
      "Allergic cough",
      "Cough associated with chronic bronchitis, pharyngitis, laryngitis, childhood and bronchitis",
    ],
    usage_instructions: [
      "Children: 1-2 teaspoonful TDS with Lukewarm water",
      "Adults: 2-3 teaspoonful TDS with Lukewarm water, or as directed by the physician",
      "Do not stop or skip the Medicine without physician’s Advice.",
      "Do not consume more than the prescribed quantity",
    ],
    key_features: [
      "Enriched with Hyssops (Jufa)- Acts as a potential anti-viral",
      "Non-sedative, Non-narcotic and safe",
      "A natural bronchodilator & experts mucolytic action",
      "Anti-Allergic & Immunomodulator",
    ],
    ingredients:
      "Tulsi patra (Ocimum Sanctum), Mulethi (Glycyrrhiza Glabra), Vasa and Jufa (Adhatoda Vasica nees & Hyssopus officinalis), Honey base etc",
    safety_information:
      "Provide the Safety information, Including warnings, Precautions, and potential Side Effects.",
  },
  // Add more items as needed
];

export default data;
