import React from "react";
import { useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import RelatedProducts from "./RelatedProducts";

const ProductDetail = () => {
  const { state } = useLocation();
  const product = state?.product;

  if (!product) {
    // Handle the case where there's no product data
    return <div>No product data found</div>;
  }

  const {
    imageSrc,
    title,
    sub_heading,
    description,
    health_benefits,
    usage_instructions,
    key_features,
    ingredients,
    safety_information,
  } = product;

  const renderList = (items) => (
    <ul className="list-none pl-6">
      {items.map((item, index) => (
        <li key={index} className="text-lg text-gray-700">
          <FaCheckCircle className="inline-block text-green-500 text-lg mr-2" />
          {item}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="lg:px-44 lg:py-10 bg-gray-100">
      {/* Banner Section */}
      <div className=" lg:flex lg:h-full bg-white">
        <div className="md:w-1/3 ">
          <img src={imageSrc} alt="ProductImage" className="w-full h-auto " />
        </div>
        <div className="lg:w-2/3 bg-white  flex items-center p-8">
          <div className="name">
            <h1 className="text-4xl font-josefin-sans font-bold text-blue-900 mb-2">
              {title}
            </h1>
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              {sub_heading}
            </h2>
            <p className="text-xl text-gray-700">{description}</p>
            <h2 className="text-3xl font-semibold text-blue-900 mt-4 mb-2">
              Health Benefits
            </h2>
            {renderList(health_benefits)}
          </div>
        </div>
      </div>
      {/* Rest of Details */}
      <div className="bg-white p-8">
        {/* Usage Instructions */}
        {usage_instructions && (
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Usage Instructions
            </h2>
            {renderList(usage_instructions)}
          </div>
        )}
        {/* Key Features */}
        {key_features && (
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Key Features
            </h2>
            {renderList(key_features)}
          </div>
        )}
        {/* Ingredients */}
        {ingredients && (
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Ingredients
            </h2>
            <p className="text-xl text-gray-700">{ingredients}</p>
          </div>
        )}
        {/* Safety Information */}
        {safety_information && (
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Safety Information
            </h2>
            <p className="text-xl text-gray-700">{safety_information}</p>
          </div>
        )}
        {/* Share Buttons */}
        <div className="mt-4">
          <h2 className="text-2xl font-semibold text-blue-900 mb-2">Share</h2>
          <div className="flex space-x-4">
            <button
              disabled
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Facebook
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
              Twitter
            </button>
          </div>
        </div>
      </div>
      <RelatedProducts/>
    </div>
  );
};

export default ProductDetail;
