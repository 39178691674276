// ProductCard.js
import React from "react";

const ProductCard = ({ product, onClick }) => {
  const { imageSrc, title, description } = product;

  return (
    <div
      className="m-5 mt-0 lg:m-0 bg-white rounded-t-xl md:w-1/3 lg:w-1/5 shadow-xl hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out"
      onClick={onClick}
    >
      <img
        className="rounded-t-2xl h-80 lg:h-64 w-full rounded-xl object-contain shadow-bottom"
        src={imageSrc}
        alt=""
      />
      <div className="product-content p-5 text-center  ">
        <h1 className="text-xl font-semibold my-2">{title}</h1>
        <p className="product-description">{description}</p>
      </div>
       
    </div>
  );
};

export default ProductCard;
